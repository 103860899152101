body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  overflow-x: hidden;
}

code {
  font-family: "Poppins", sans-serif;
}

:root {
  /* Footer Color */
  --footer-letter: #fff;
  --footer-bg: #000;

  /* Header Color */
  --header-letter: #fff;
  --header-bg: #000;

  /* White Colors */
  --white: #fff;

  /* Black Colors */
  --black: #211c1d;
  --black-second: #2c2c2e;
  --black-third: #30404d;

  /* Gray Colors */
  --gray: #00000029;
  --gray-primary: #f5f5f5;

  /* Red Colors */
  --red: #ff0000;

  /* Blue Colors */
  --blue: #0092f6;
  --blue-second: #00a8ff;
  --light-blue: #0092f629;
  --light-blue2: #e7f5fe;

  /* Orange Colors */
  --orange: #f6ac00;
}
